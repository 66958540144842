type AvailableLocale = "nl" | "en" | "fr"

const availableLocales: AvailableLocale[] = ["nl", "fr", "en"]

const showBanner = (locale: AvailableLocale): void => {
	const banner = document.getElementById(`locale-${locale}`) as HTMLDivElement
	banner.style.display = "block"
}

const setSavedLocale = (locale: AvailableLocale): void => {
	window.localStorage.setItem("locale", locale)
}

const localeSettingLinks = document.querySelectorAll(
	"[data-locale]"
) as NodeListOf<HTMLAnchorElement>
localeSettingLinks.forEach(link =>
	link.addEventListener("click", () => handleClick(link))
)

const handleClick = (link: HTMLAnchorElement): boolean => {
	const locale = link.dataset.locale as AvailableLocale
	setSavedLocale(locale)
	return true
}

const getSavedLocale = (): AvailableLocale | null => {
	const item = window.localStorage.getItem("locale")
	switch (item) {
		case "nl":
		case "en":
		case "fr":
			return item
		default:
			return null
	}
}

const redirect = (savedLocale: AvailableLocale): void => {
	const { origin, pathname } = window.location
	const localePath = savedLocale === "nl" ? "" : `/${savedLocale}`
	const pathWithoutLocale = pathname.replace(/\/(en|fr)/, "")
	const cleanedFilePath = pathWithoutLocale === "/" ? "" : pathWithoutLocale
	const target = origin + localePath + cleanedFilePath
	window.location.replace(target)
}

const languageBanner = (): void => {
	const pageLocale = document.documentElement
		.getAttribute("lang")
		?.toLowerCase()

	if (!pageLocale) return

	const savedLocale = getSavedLocale()

	if (savedLocale) {
		if (savedLocale !== pageLocale) {
			redirect(savedLocale)
		}
		return
	}

	const getBrowserLocales = () => {
		const browserLocales =
			navigator.languages === undefined
				? [navigator.language]
				: navigator.languages

		if (!browserLocales) {
			return undefined
		}

		// Trim, remove country codes, convert to lowercase
		const trimmedLocales = browserLocales.map(locale =>
			locale.trim().split(/-|_/)[0].toLowerCase()
		)

		// Remove duplicates
		return [...new Set(trimmedLocales)]
	}

	const browserLocales = getBrowserLocales()
	if (!browserLocales) return

	// If the browser locales are in the available locales, set them as the preferred locale
	// (following the order of priority set by availableLocaes)
	const preferredLocale = availableLocales.find(
		availableLocale => browserLocales.indexOf(availableLocale) > -1
	) as "nl" | "fr" | "en" | undefined

	if (!preferredLocale && pageLocale !== "nl") {
		showBanner("nl")
	} else if (preferredLocale && pageLocale !== preferredLocale) {
		showBanner(preferredLocale)
	}
}

languageBanner()